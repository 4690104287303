import { Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { QuickpanelInfoService } from 'src/app/services/quickpanel-info.service';

@Component({
  selector: 'vex-quickpanel',
  templateUrl: './quickpanel.component.html',
  styleUrls: ['./quickpanel.component.scss']
})
export class QuickpanelComponent implements OnInit {

  date = DateTime.local().toFormat('DD');
  dayName = DateTime.local().toFormat('EEEE');

  constructor(public quickPanel: QuickpanelInfoService) { }

  ngOnInit() {
    this.quickPanel.load();
  }

}
