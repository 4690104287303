import { Injectable } from '@angular/core';
import { AuthService } from '../../bh/auth.service';
import { Interview } from '../models/Interview';

@Injectable({
    providedIn: 'root'
  })
export class QuickpanelInfoService {
    
    interviews!: Interview[];
    loading = true;

    constructor(private _auth: AuthService)
    {
    }

    load() {
        this._auth.get('eval/todaysEvals').subscribe(r => {
            this.interviews = r;
            this.loading = false;
        });
    }

}  