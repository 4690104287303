<div class="footer flex">
  <div class="flex-auto flex items-center container">
    <a class="action flex-none"
       color="primary"
       href="//lepsinc.com"
       id="go-leps"
       mat-flat-button>LEPS Website</a>
    <div class="font-medium ltr:ml-4 rtl:mr-4 hidden sm:block">
      Copyright LEPS {{year}}
    </div>
  </div>
</div>
