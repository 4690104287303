import { throwError } from 'rxjs';

export abstract class BaseService {  
    
    constructor() { }

    protected handleError(error: any) {
      if (error.headers) {
        var applicationError = error.headers.get('Application-Error');
        if (applicationError) {
          return  throwError(new Error(applicationError));
        }
      }
      if (error.error) {
        let desc: string = '';
        for(var key in error.error) {
          desc += error.error[key] + '\n';
        }
        return throwError(desc);
      }
      if (error.status) {
        switch (error.status) {
          case 401:
            return throwError('Unauthorized');
        }
      }
      return throwError(error)
    }
}