import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class ReturnUrlService {
    private returnUrl: string = '/';

    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) {
    }

    init(defaultUrl: string): void {
        this.route.queryParams.subscribe(params => this.returnUrl = params['returnUrl'] || defaultUrl);
    }

    navPwd(password: string): void {
        const url = password.toLowerCase().substring(0,6) === 'reset_' ? '/changePassword' : this.returnUrl;
        this.router.navigate([url]);
    }

    nav() : void {
        this.router.navigate([this.returnUrl]);
    }

}