import { Injectable } from '@angular/core';
import { AuthService } from '../../bh/auth.service';
import { Interview } from '../models/Interview';
import { DateTime } from 'luxon';
import { Notification } from 'src/@vex/layout/toolbar/toolbar-notifications/interfaces/notification.interface';

@Injectable({
    providedIn: 'root'
  })
export class NotifyService {
    
    notifications: Notification[] = [];
    loading = true;

    constructor(private _auth: AuthService)
    {
    }

    notificationFromEval(interview: Interview) : Notification {
        let notification = {
            id: interview.id,
            label: interview.f_name + ' ' + interview.l_name,
            datetime: DateTime.fromJSDate(interview.interviewDate),
            colorClass: 'text-primary-500',
            icon: "msi:no_meeting_room"
        };
        switch (interview.concern) {
            case 1:
                notification.label += ' - Overdue Interview';
                notification['icon'] = "mat:no_meeting_room";
                notification['colorClass'] = 'text-primary-500';
                break;
            case 2:
                notification.label += ' - Overdue Report';
                notification['icon'] = "mat:report";
                notification['colorClass'] = 'text-orange-500';
                break;
            case 3:
                notification.label += ' - Overdue Hold';
                notification['icon'] = "mat:watch_later";
                notification['colorClass'] = 'text-purple-500';
                break;
        }
        return notification;
    }

    load() {
        if (this.notifications.length) return;
        this._auth.get('eval/evalConcerns').subscribe((interviews: Interview[]) => {
            this.notifications = [];
            interviews.forEach(i => this.notifications.push(this.notificationFromEval(i)));
            this.loading = false;
            //console.debug('todaysEvals', interviews, this.notifications);
        });
    }

}  