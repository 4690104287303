import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { AuthService, LoginUrl } from 'src/bh/auth.service';
import { ReturnUrlService } from '../services/return-url.service';


@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate, CanActivateChild {

  constructor(private auth: AuthService, private router: Router, private returnUrl: ReturnUrlService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.isLoggedIn) {
      return true;
    } else {
      this.returnUrl.init('/home');
      // this.router.navigate([LoginUrl], {
      //   this.router.navigate([LoginUrl], {
      //   queryParams: {
      //     return: state.url
      //   }
      // });
      this.router.navigate([LoginUrl]);
      return false;
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(childRoute, state);
  }
}