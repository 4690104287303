import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { AuthGuardService } from './models/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    component: CustomLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'underdev',
        loadChildren: () => import('./under-dev/under-dev.module').then(m => m.UnderDevModule)
      },
      {
        path: 'log',
        loadChildren: () => import('./log/event-log.module').then(m => m.EventLogModule)
      },
      {
        path: 'report',
        canActivateChild: [AuthGuardService],
        children:[
          {
            path: 'list',
            loadChildren: () => import('./reports/report-list/report-list.module').then(m => m.ReportListModule)
          },
          {
            path: 'view/:id',
            loadChildren: () => import('./reports/report-grid/report-grid.module').then(m => m.ReportGridModule)
          },
          {
            path: 'admin',
            loadChildren: () => import('./reports/report-admin/report-admin.module').then(m => m.ReportAdminModule)
          },
          {
            path: 'edit/:id',
            loadChildren: () => import('./reports/report-edit/report-edit.module').then(m => m.ReportEditModule)
          },
          {
            path: 'cube/:id',
            loadChildren: () => import('./reports/report-cube/report-cube.module').then(m => m.ReportCubeModule)
          }
        ]
      },
      {
        path: 'maint',
        canActivateChild: [AuthGuardService],
        children: [
          {
            path: 'users',
            loadChildren: () => import('./maint/user/user.module').then(m => m.MaintUserModule)
          },
          {
            path: 'impersonate',
            loadChildren: () => import('./account/impersonate/impersonate.module').then(m => m.ImpersonateModule)
          },
          {
            path: 'agencygroups',
            loadChildren: () => import('./maint/agency-group/agency-group.module').then(m => m.MaintAgencyGroupModule)
          },
          {
            path: 'config',
            loadChildren: () => import('./maint/config/config.module').then(m => m.MaintConfigModule)
          },
          {
            path: 'email',
            loadChildren: () => import('./maint/email/email-template.module').then(m => m.EmailTemplateModule)
          },
          {
            path: 'testoptions',
            children: [
              {
                path: '',
                redirectTo: 'index',
                pathMatch: 'full'
              },
              {
                path: 'index',
                loadChildren: () => import('./maint/test-options/test-options.module').then(m => m.TestOptionsModule)
              },
              {
                path: 'add',
                loadChildren: () => import('./maint/test-options/test-options-edit/test-options-edit.module').then(m => m.TestOptionsEditModule)
              },
              {
                path: 'edit/:id',
                loadChildren: () => import('./maint/test-options/test-options-edit/test-options-edit.module').then(m => m.TestOptionsEditModule)
              }
            ]
          },
          {
            path: 'setdef',
            loadChildren: () => import('./maint/set-def/set-def.module').then(m => m.SetDefModule)
          },
          {
            path: 'testconfigedit',
            loadChildren: () => import('./maint/test-options/test-options-edit/test-options-edit.module').then(m => m.TestOptionsEditModule)
          }
        ]
      },
      {
        path: 'eval',
        canActivateChild: [AuthGuardService],
        children: [
          {
            path: 'upcoming',
            loadChildren: () => import('./eval/upcoming/upcoming.module').then(m => m.UpcomingModule)
          },
          {
            path: 'current',
            loadChildren: () => import('./eval/current/current.module').then(m => m.CurrentModule)
          },
          {
            path: 'complete',
            loadChildren: () => import('./eval/complete/complete.module').then(m => m.CompleteModule)
          },
          {
            path: 'manager',
            loadChildren: () => import('./eval/manager/manager-eval.module').then(m => m.ManagerEvalModule)
          },
          {
            path: 'admin',
            loadChildren: () => import('./eval/admin/admin-eval.module').then(m => m.AdminEvalModule)
          }
        ]
      },
      {
        path: 'quest',
        canActivateChild: [AuthGuardService],
        children: [
          {
            path: 'questionnaire',
            children: [
              {
                path: '',
                redirectTo: 'index',
                pathMatch: 'full'
              },
              {
                path: 'index',
                loadChildren: () => import('./quest/questionnaire/questionnaire.module').then(m => m.QuestionnaireModule)
              },
              {
                path: 'edit/:id',
                loadChildren: () => import('./quest/questionnaire/questionnaire-edit/questionnaire-edit.module').then(m => m.QuestionnaireEditModule)
              },
              {
                path: 'add',
                loadChildren: () => import('./quest/questionnaire/questionnaire-edit/questionnaire-edit.module').then(m => m.QuestionnaireEditModule)
              },
              {
                path: 'editQuestion/:id',
                loadChildren: () => import('./quest/questionnaire/question-edit/question-edit.module').then(m => m.QuestionEditModule)
              },
              {
                path: 'addQuestion',
                loadChildren: () => import('./quest/questionnaire/question-edit/question-edit.module').then(m => m.QuestionEditModule)
              },
              {
                path: 'editTemplate/:qId/:id',
                loadChildren: () => import('./quest/questionnaire/template-edit/template-edit.module').then(m => m.TemplateEditModule)
              },
              {
                path: 'addTemplate/:qId',
                loadChildren: () => import('./quest/questionnaire/template-edit/template-edit.module').then(m => m.TemplateEditModule)
              },
              {
                path: 'editSubquestion/:id',
                loadChildren: () => import('./quest/questionnaire/subquestion-edit/subquestion-edit.module').then(m => m.SubquestionEditModule)
              },
              {
                path: 'addSubquestion',
                loadChildren: () => import('./quest/questionnaire/subquestion-edit/subquestion-edit.module').then(m => m.SubquestionEditModule)
              }
            ]
          },
          {
            path: 'batch',
            children: [
              {
                path: '',
                redirectTo: 'index',
                pathMatch: 'full'
              },
              {
                path: 'index',
                loadChildren: () => import('./quest/batch/batch.module').then(m => m.BatchModule)
              },
              {
                path: 'add',
                loadChildren: () => import('./quest/batch/batch-edit/batch-edit.module').then(m => m.BatchEditModule)
              },
              {
                path: 'edit/:id',
                loadChildren: () => import('./quest/batch/batch-edit/batch-edit.module').then(m => m.BatchEditModule)
              }
            ]
          },
          {
            path: 'proctor',
            children: [
              {
                path: '',
                redirectTo: 'session',
                pathMatch: 'full'
              },
              {
                path: 'session',
                loadChildren: () => import('./quest/proctor/proctor-session.module').then(m => m.ProctorSessionModule)
              },
              {
                path: 'add',
                loadChildren: () => import('./quest/proctor/session-edit/proctor-session-edit.module').then(m => m.ProctorSessionEditModule)
              },
              {
                path: 'edit/:id',
                loadChildren: () => import('./quest/proctor/session-edit/proctor-session-edit.module').then(m => m.ProctorSessionEditModule)
              }

            ]
          },
          {
            path: 'session',
            children: [
              {
                path: '',
                redirectTo: 'index',
                pathMatch: 'full'
              },
              {
                path: 'index',
                loadChildren: () => import('./quest/session/session.module').then(m => m.SessionModule)
              }
            ]
          }
        ]
      },
      {
        path: 'qb',
        loadChildren: () => import('./qb/qb.module').then(m => m.QbModule)
      }
      // {
      //   path: 'qb',
      //   children: [
      //     {
      //       path: 'auth',
      //       loadChildren: () => import('./qb/auth/qb-auth.module').then(m => m.QbAuthModule)
      //     },
      //     {
      //       path: 'home',
      //       loadChildren: () => import('./qb/home/qb-home.module').then(m => m.QHomeModule)
      //     }
      //   ]
      // }
    ]
  },
  {
    path: 'archive',
    children: [
      {
        path: '',
        redirectTo: 'index',
        pathMatch: 'full'
      },
      {
        path: 'index',
        loadChildren: () => import('./archive/archive.module').then(m => m.ArchiveModule)
      }
    ]
  },
  { 
    path: 'account',
    children:[
      {
        path: 'login',
        loadChildren: () => import('./account/login/login.module').then(m => m.LoginModule)
      },
      {
        path: 'forgot-password',
        loadChildren: () => import('./account/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
      },
      {
        path: 'reset-password',
        loadChildren: () => import('./account/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
      },
      {
        path: 'change-password',
        canActivate: [AuthGuardService],
        component: CustomLayoutComponent,
        loadChildren: () => import('./account/change-password/change-password.module').then(m => m.ChangePasswordModule)
      },
      {
        path: 'profile',
        canActivate: [AuthGuardService],
        component: CustomLayoutComponent,
        loadChildren: () => import('./account/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: '2faSelect',
        loadChildren: () => import('./account/twofa-select/twofa-select.module').then(m => m.TwofaSelectModule)
      },
      {
        path: '2faTotpSetup',
        loadChildren: () => import('./account/twofa-totp-setup/twofa-totp-setup.module').then(m => m.TwofaTotpSetupModule)
      },
      {
        path: '2fa/:type',
        loadChildren: () => import('./account/twofa/twofa.module').then(m => m.TwofaModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    // relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
