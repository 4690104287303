import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import { AuthService } from 'src/bh/auth.service';

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: string;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit {

  items!: MenuItem[];
  name!: string;

  trackById = trackById;

  constructor(private cd: ChangeDetectorRef,
    private auth: AuthService,
    private popoverRef: PopoverRef<ToolbarUserDropdownComponent>
  ) {
    this.name = this.auth.getName();
    this.items = [
      {
        id: '1',
        icon: 'mat:account_circle',
        label: 'My Profile',
        description: 'User Name & Email',
        colorClass: 'text-teal',
        route: '/account/profile'
      },
      {
        id: '2',
        icon: 'mat:lock',
        label: 'Change Password',
        description: 'Change Your Password',
        colorClass: 'text-primary',
        route: '/account/change-password'
      }
    ];
    if (this.auth.isAdmin) {
      this.items.push({
        id: '3',
        icon: 'mat:person',
        label: 'Impersonate',
        description: 'Change app filter to selected Psych',
        colorClass: 'text-secondary',
        route: '/maint/impersonate'
      });
    }
  }

  ngOnInit() {
  }

  close() {
    this.popoverRef.close();
  }

  logout() {
    this.close();
    this.auth.logout();
  }
}
