import { Component, OnInit } from '@angular/core';
import { Notification } from '../interfaces/notification.interface';
import { trackById } from '../../../../utils/track-by';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'vex-toolbar-notifications-dropdown',
  templateUrl: './toolbar-notifications-dropdown.component.html',
  styleUrls: ['./toolbar-notifications-dropdown.component.scss']
})
export class ToolbarNotificationsDropdownComponent implements OnInit {

  trackById = trackById;

  constructor(public notify: NotifyService) { }

  ngOnInit() {
    this.notify.load();
  }

}
