import { Injectable } from '@angular/core';
import { AuthService } from '../../bh/auth.service';
import { NavigationService } from '../../@vex/services/navigation.service';
import { NavigationItem, NavigationLink } from '../../@vex/interfaces/navigation-item.interface';
import { LayoutService } from '../../@vex/services/layout.service';

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    private initialized = false;

    constructor(
        private auth: AuthService,
        private navigationService: NavigationService,
        private layoutService: LayoutService

    ) {
    }

    checkMenu() {
        // console.debug('checkMenu', this.navigationService.items, this.auth.isLoggedIn);
        if (!this.initialized && this.auth.isLoggedIn) {
            this.setMenu();
        }
    }

    setMenu() {
        // console.debug('setMenu');
        const roles = this.auth.roles;
        const isAdmin = roles.indexOf('Admin') >= 0;
        const isAccountant = roles.indexOf('Accountant') >= 0;
        const isMgr = roles.indexOf('Manager') >= 0 || isAdmin;
        const isUpcoming = roles.indexOf('UpcomingEval') >= 0 || isMgr || isAdmin;
        const isQuestAdmin = roles.indexOf('QuestAdmin') >= 0 || isAdmin;
        const isQuestMgr = roles.indexOf('QuestMgr') >= 0 || isQuestAdmin;
        let items: NavigationItem[] = [
            {
                type: 'link',
                label: 'Home',
                route: '/home',
                icon: "mat:home"
            }
        ];
        if (isMgr) {
            items.push({ type: 'link', label: 'Reports', route: '/report/list', icon: "mat:list" });
        }
        if (isAccountant) {
            items.push({type: 'link', label: 'QuickBooks Bridge', route:'/qb/home', icon: "mat:account_box"});
        }
        let children: NavigationLink[] = [
            { type: 'link', label: 'Current', route: '/eval/current', icon: "mat:assignment_ind" },
            { type: 'link', label: 'Completed', route: '/eval/complete', icon: "mat:assignment_turned_in" },
        ];
        if (isUpcoming) {
            children.splice(0, 0, { type: 'link', label: 'Upcoming', route: '/eval/upcoming', icon: "mat:assignment" });
        }
        if (isMgr) {
            children.push({ type: 'link', label: 'Management Review', route: '/eval/manager', icon: "mat:assessment" })
            children.push({ type: 'link', label: 'Admin', route: '/eval/admin', icon: "mat:assessment" })
        }
        items.push({
            type: 'subheading',
            label: 'Evaluations',
            children: children
        });
        if (isMgr) {
            children = [{ type: 'link', label: 'Reports', route: '/report/admin', icon: "mat:settings_applications" }];
            if (isAdmin) {
                children.push({ type: 'link', label: 'Users', route: '/maint/users', icon: "mat:person" });
                children.push({ type: 'link', label: 'Agency Groups', route: '/maint/agencygroups', icon: "mat:people" });
                children.push({ type: 'link', label: 'Test Options', route: '/maint/testoptions/index', icon: "mat:settings_remote" });
                children.push({ type: 'link', label: 'Email Templates', route: '/maint/email', icon: "mat:email" });
            }
            children.push({ type: 'link', label: 'Config Settings', route: '/maint/config', icon: "mat:style" });
            children.push({ type: 'link', label: 'Set Definitions', route: '/maint/setdef', icon: "mat:library_books" });
            items.push({
                type: 'subheading',
                label: 'Maintenance',
                children: children
            });
        }
        if (isQuestMgr) {
            children = [
                {type: 'link', label: 'Batch', route: '/quest/batch', icon: "mat:group_work"},
                {type: 'link', label: 'Proctor', route: '/quest/proctor', icon: "mat:date_range"},
                {type: 'link', label: 'Sessions', route: '/quest/session', icon: "mat:question_answer" }
            ];
            if (isQuestAdmin) {
                children.push({type: 'link', label: 'Questionnaires', route: '/quest/questionnaire/index', icon: "mat:help"});
            }
            if (isAdmin) {
                children.push({type: 'link', label: 'Archive', route: '/archive/index', icon: "mat:folder"});
            }
            items.push({
                type: 'subheading',
                label: 'Testing',
                children: children
            });
        }
        // items.push({
        //     type: 'subheading',
        //     label: 'Customize',
        //     children: [
        //         {
        //             type: 'link',
        //             label: 'Configuration',
        //             route: () => this.layoutService.openConfigpanel(),
        //             icon: "mat:settings"
        //         }
        //     ]
        // });
        this.navigationService.items = items;
        this.initialized = true;
    }
    //NOTE: Remove vex-config from custom-layout.component.html
} 