import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpErrorResponse, HttpEvent, HttpResponse, HttpContext, HttpParams } from '@angular/common/http';
import { Observable, throwError, Subscription, of, Subject } from 'rxjs';
import { tap, map, catchError, last, switchMap } from 'rxjs/operators';
import { BaseService } from "./base.service";
import { Router } from '@angular/router';
import { AlertService } from 'src/bh/alert/alert.service';
import { NavigationService } from 'src/@vex/services/navigation.service';

export interface ICredentials {
  userName: string,
  password: string
}

const HTTP_STATUS_SERVER_ERROR = 500;
const HTTP_STATUS_BADREQUEST = 400;
export const HTTP_STATUS_UNAUTHORIZED = 401;
const HTTP_STATUS_NOT_FOUND = 404;
const HTTP_STATUS_FORBIDDEN = 403;
const HTTP_STATUS_NOT_ACCEPTABLE = 406;

const APP_ROLE = 'AppTracker';

interface IJwt {
  id: any,
  auth_token: string,
  expires_in: number,
  name: string,
  roles: string[],
  claims: { [key: string]: any }
}

export enum TwoFactorType {
  Unselected = 0,
  None,
  TOTP,
  SMS,
  Email,
  Fido2,
  Passkey
}

function coerceToArrayBuffer(thing: any, name: string) {
  if (typeof thing === "string") {
    // base64url to base64
    thing = thing.replace(/-/g, "+").replace(/_/g, "/");

    // base64 to Uint8Array
    var str = window.atob(thing);
    var bytes = new Uint8Array(str.length);
    for (var i = 0; i < str.length; i++) {
      bytes[i] = str.charCodeAt(i);
    }
    thing = bytes;
  }

  // Array to Uint8Array
  if (Array.isArray(thing)) {
    thing = new Uint8Array(thing);
  }

  // Uint8Array to ArrayBuffer
  if (thing instanceof Uint8Array) {
    thing = thing.buffer;
  }

  // error if none of the above worked
  if (!(thing instanceof ArrayBuffer)) {
    throw new TypeError("could not coerce '" + name + "' to ArrayBuffer");
  }

  return thing;
};


function coerceToBase64Url(thing: any) {
  // Array or ArrayBuffer to Uint8Array
  if (Array.isArray(thing)) {
    thing = Uint8Array.from(thing);
  }

  if (thing instanceof ArrayBuffer) {
    thing = new Uint8Array(thing);
  }

  // Uint8Array to base64
  if (thing instanceof Uint8Array) {
    var str = "";
    var len = thing.byteLength;

    for (var i = 0; i < len; i++) {
      str += String.fromCharCode(thing[i]);
    }
    thing = window.btoa(str);
  }

  if (typeof thing !== "string") {
    throw new Error("could not coerce to string");
  }

  // base64 to base64url
  // NOTE: "=" at the end of challenge is optional, strip it off here
  thing = thing.replace(/\+/g, "-").replace(/\//g, "_").replace(/=*$/g, "");

  return thing;
};

function fromBase64Url(base64url: any) {
  let base64 = base64url.replace(/\_/g, "/").replace(/\-/g, "+");
  return Uint8Array.from(atob(base64), c => c.charCodeAt(0));
}

export const LoginUrl = '/account/login';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {

  constructor(private _http: HttpClient, private _router: Router, public alert: AlertService, private nav: NavigationService) {
    super();
  }

  private readonly lsAuthToken: string = 'auth_token';
  private readonly lsName: string = 'name';
  private readonly lsRoles: string = 'roles';
  private readonly lsClaims: string = 'claims';
  private readonly lsExpires: string = 'expires'

  readonly apiUrl: string = '/api/';

  public getAccessToken(): string | null {
    return localStorage.getItem(this.lsAuthToken);
  }

  private setAccessToken(token: string) {
    localStorage.setItem(this.lsAuthToken, token);
  }

  public getName(): string {
    const name = localStorage.getItem(this.lsName);
    return !!name ? name : 'Anonymous';
  }

  private setName(name: string) {
    localStorage.setItem(this.lsName, name);
  }

  public get roles(): string[] {
    var s = localStorage.getItem(this.lsRoles);
    return s ? JSON.parse(s) : [];
  }

  public get isAdminOrMgr(): boolean {
    const roles = this.roles;
    return roles.indexOf('Admin') >= 0 || roles.indexOf('Manager') >= 0;
  }
  
  public hasRole(role: string): boolean {
    const roles = this.roles;
    return roles.indexOf(role) >= 0;
  }

  private setRoles(roles: string[]) {
    var s = JSON.stringify(roles);
    localStorage.setItem(this.lsRoles, s);
  }

  public getClaims(): { [key: string]: any } {
    const s = localStorage.getItem(this.lsClaims);
    return s ? JSON.parse(s) : [];
  }

  private setClaims(claims: { [key: string]: any }) {
    var s = JSON.stringify(claims);
    localStorage.setItem(this.lsClaims, s);
  }

  private setExpires(expires: number) {
    var s = JSON.stringify(expires);
    localStorage.setItem(this.lsExpires, s);
  }

  private getExpires(): number {
    return Number(localStorage.getItem(this.lsExpires));
  }

  public logout() {
    localStorage.removeItem(this.lsAuthToken);
    localStorage.removeItem(this.lsName);
    localStorage.removeItem(this.lsRoles);
    localStorage.removeItem(this.lsExpires);
    this._router.navigate([LoginUrl]);
  }

  public login(credentials: ICredentials, minRole: string | null = null): Observable<any> {
    // return this._http.post<IJwt>(this.apiUrl + 'account/login', credentials)
    return this._http.post<IJwt>(this.apiUrl + 'account/auth', credentials)
      .pipe(map(r => {
        this.authorize(r, null);
        return Number(r.claims['tfa']);
      }), catchError(e => this.handleHttpError(e, this._router))
      );
  }

  public impersonate(psych: string): Observable<any> {
    const options = this.getHttpOptions();
    return this._http.get<IJwt>(this.apiUrl + 'account/impersonate?id=' + psych, options)
      .pipe(map(r => {
        this.setAccessToken(r.auth_token);
        this.setName(r.claims['name'] ? r.claims['name'] : r.name);
        this.setRoles(r.roles);
        this.setClaims(r.claims);
        const expires = new Date().getTime() + r.expires_in * 1000;
        this.setExpires(expires);
        return true;
      }), catchError(e => this.handleHttpError(e, this._router))
      );
  }

  loginUser(userName: string): Observable<any> {
    return this._http.post<any>('/api/account/assertionOptions', { userName }).pipe(
      switchMap(async r => {
        if (r.status !== 'ok') {
          throw r.errorMessage;
        }
        let options = r;
        options.challenge = fromBase64Url(options.challenge);
        options.allowCredentials.forEach((x: any) => x.id = fromBase64Url(x.id));
        //const publicKey = this.preformatGetAssertReq(r);
        return navigator.credentials.get({ publicKey: options });
      }),
      switchMap((assertedCredential: any) => {
        let authData = new Uint8Array(assertedCredential.response.authenticatorData);
        let clientDataJSON = new Uint8Array(assertedCredential.response.clientDataJSON);
        let rawId = new Uint8Array(assertedCredential.rawId);
        let sig = new Uint8Array(assertedCredential.response.signature);
        const data = {
          id: assertedCredential.id,
          rawId: coerceToBase64Url(rawId),
          type: assertedCredential.type,
          extensions: assertedCredential.getClientExtensionResults(),
          response: {
            authenticatorData: coerceToBase64Url(authData),
            clientDataJSON: coerceToBase64Url(clientDataJSON),
            signature: coerceToBase64Url(sig)
          }
        };
        return this._http.post<IJwt>('/api/account/makeAssertion', data);
      }),
      map(r => {
        this.nav.items = [];
        return this.authorize(r, APP_ROLE);
      }), catchError(e => this.handleHttpError(e, this._router))
    );
  }

  selectNoTfa(): Observable<any> {
    const options = this.getHttpOptions();
    console.debug('selectNoTfa', options);
    return this._http.get<any>('/api/account/notfa', options)
      .pipe(map((r: any) => {
        this.nav.items = [];
        return this.authorize(r, APP_ROLE);
      }),
        catchError(e => this.handleHttpError(e, this._router))
      )
  }

  // webAuthnSignup(): any {
  //   let publicKeyCredentialCreationOptions: PublicKeyCredentialCreationOptions = {
  //     "rp": {
  //         "id": "localhost",
  //         "name": "LepsWeb"
  //     },
  //     "user": {
  //         "name": "bhood",
  //         "id": coerceToArrayBuffer("ew47bJ89iECohateAQapQg", 'user.id'),
  //         "displayName": "Ben Hood"
  //     },
  //     "challenge": coerceToArrayBuffer("3cwvVpUWwu1USI9vx-vlmw", 'challenge'),
  //     "pubKeyCredParams": [
  //         {
  //             "type": "public-key",
  //             "alg": -7
  //         },
  //         {
  //             "type": "public-key",
  //             "alg": -257
  //         },
  //         {
  //             "type": "public-key",
  //             "alg": -37
  //         },
  //         {
  //             "type": "public-key",
  //             "alg": -35
  //         },
  //         {
  //             "type": "public-key",
  //             "alg": -258
  //         },
  //         {
  //             "type": "public-key",
  //             "alg": -38
  //         },
  //         {
  //             "type": "public-key",
  //             "alg": -36
  //         },
  //         {
  //             "type": "public-key",
  //             "alg": -259
  //         },
  //         {
  //             "type": "public-key",
  //             "alg": -39
  //         },
  //         {
  //             "type": "public-key",
  //             "alg": -8
  //         }
  //     ],
  //     "timeout": 60000,
  //     "attestation": "none",
  //     "authenticatorSelection": {
  //         "requireResidentKey": false,
  //         "userVerification": "preferred"
  //     },
  //     "excludeCredentials": [
  //         {
  //             "type": "public-key",
  //             "id": coerceToArrayBuffer("oGZ4CaAbM_VCh2kadKQWPEtsNcvgGp7LUP3fsZvhaUY", 'excludeCredentials.id')
  //         }
  //     ],
  //     // "extensions": {
  //     //     "uvm": true
  //     // },
  //     // "status": "ok",
  //     // "errorMessage": ""
  // };
  //     return navigator.credentials.create({ publicKey: publicKeyCredentialCreationOptions });
  // }

  registerUser(tfa: boolean) {
    const options = this.getHttpOptions();
    return this._http.get<any>('/api/account/genCredentialOptions?tfa=' + tfa, options).pipe(
      switchMap(r => {
        if (r.status !== 'ok') {
          throw r.errorMessage;
        }
        let options: PublicKeyCredentialCreationOptions = {
          rp: r.rp,
          user: {
            name: r.user.name,
            id: coerceToArrayBuffer(r.user.id, 'user.id'),
            displayName: r.user.displayName
          },
          challenge: coerceToArrayBuffer(r.challenge, 'challenge'),
          pubKeyCredParams: r.pubKeyCredParams,
          timeout: r.timeout,
          attestation: 'none',
          authenticatorSelection: r.authenticatorSelection,
          excludeCredentials: r.excludeCredentials.map((c: PublicKeyCredentialDescriptor) => {
            return {id: coerceToArrayBuffer(c.id, 'excludeCredentials.id'), type: c.type};
          })
        };
        return navigator.credentials.create({ publicKey: options });
      }),
      switchMap((newCredential: any) => {
        if (!newCredential) return of();
        let attestationObject = new Uint8Array(newCredential.response.attestationObject);
        let clientDataJSON = new Uint8Array(newCredential.response.clientDataJSON);
        let rawId = new Uint8Array(newCredential.rawId);

        const data = {
          id: newCredential.id,
          rawId: coerceToBase64Url(rawId),
          type: newCredential.type,
          extensions: newCredential.getClientExtensionResults(),
          response: {
            AttestationObject: coerceToBase64Url(attestationObject),
            clientDataJSON: coerceToBase64Url(clientDataJSON)
          }
        };

        return this._http.post<IJwt>('/api/account/registerCredentials', data, options);
      }),
      map((r: IJwt) => {
        this.nav.items = [];
        return this.authorize(r, APP_ROLE);
      }), catchError(e => this.handleHttpError(e, this._router))
    );
  }

  submit2fa(code: string): Observable<any> {
    const options = this.getHttpOptions();
    return this._http.post<any>('/api/account/submitTotp', { pin: code }, options)
      .pipe(map((r: any) => this.authorize(r)), catchError((e: any) => {
        if (e.status == HTTP_STATUS_UNAUTHORIZED) {
          this.alert.error('Invalid Code. Please retry.');
          return of(false);
        }
        const msg = this.httpErrorMessage(e);
        this.alert.error(msg);
        throw e;
      }))
  }

  confirm2fa(code: string): Observable<any> {
    const options = this.getHttpOptions();
    return this._http.post<any>('/api/account/twofa', { pin: code }, options)
      .pipe(map((r: any) => this.authorize(r)), catchError((e: any) => {
        if (e.status == HTTP_STATUS_UNAUTHORIZED) {
          this.alert.error('Invalid Code. Please retry.');
          return of(false);
        }
        const msg = this.httpErrorMessage(e);
        this.alert.error(msg);
        throw e;
      }))
  }

  confirmAccess(): boolean {
    if (!this.hasRole(APP_ROLE)) {
      this.alert.error('Not authorized for this module.');
      return false;
    }
    return true;
  }

  private authorize(r: IJwt, minRole: string | null = null): boolean {
    if (minRole && r.roles.indexOf(minRole) < 0) {
      this.alert.error('Not authorized for this module.');
      console.error('Unauthorized');
      return false;
    }
    this.setAccessToken(r.auth_token);
    this.setName(r.claims['name'] ? r.claims['name'] : r.name);
    this.setRoles(r.roles);
    this.setClaims(r.claims);
    const expires = new Date().getTime() + r.expires_in * 1000;
    this.setExpires(expires);
    return true;
  }

  private getHttpOptions(): {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  } {
    let token = this.getAccessToken();
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }),
      params: {},
      responseType: 'json'
    };
  }

  public post(url: string, body: any): Observable<any> {
    const options = this.getHttpOptions();
    return this._http.post(this.apiUrl + url, body, options).pipe(catchError(e => this.handleHttpError(e, this._router)));
  }

  public get(url: string, params = {}): Observable<any> {
    let options = this.getHttpOptions();
    if (Object.keys(params).length > 0) {
      options.params = params;
    }
    console.debug('httpGet', url, options, params);
    return this._http.get(this.apiUrl + url, options).pipe(catchError(e => this.handleHttpError(e, this._router)));
  }

  public getNoHdr(url: string) {
    return this._http.get(this.apiUrl + url).pipe(catchError(e => this.handleHttpError(e, this._router)));
  }

  public get isAdmin(): boolean {
    return this.roles.indexOf('Admin') >= 0;
  }

  public uploadFileRequest(url: string, file: FileUploadModel, param = 'file', values: { [key: string]: any }): Observable<string | HttpResponse<unknown> | null> {
    const fd = new FormData();
    fd.append(param, file.data);
    if (values) {
      Object.keys(values).forEach(key => {
        fd.append(key, values[key]);
      });
    }
    const token = this.getAccessToken();
    const options = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token }),
      reportProgress: true
    };
    const req = new HttpRequest('POST', this.apiUrl + url, fd, options);
    return this._http.request(req).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            if (!!event.total)
              file.progress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
        return null;
      }),
      tap(message => { }),
      last(),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        file.canRetry = true;
        //console.debug('error', error);
        file.error = this.httpErrorMessage(error);
        throwError(file.error);
        const message = this.httpErrorMessage(error);
        return of(message);
        //return of(`${file.data.name} upload failed. ${message}`);
      })
    );
  }

  //errorMessage: Observable<string> = new Observable<string>();

  public downloadFile(url: string, body: any, fileName: string): Observable<any> {
    let options = this.getHttpOptions();
    options.responseType = 'blob' as 'json';
    //console.debug('downloadFile', url, body, fileName);
    return this._http.post(this.apiUrl + url, body, options)
      .pipe(map((r: any) => {
        const dataType = r.type;
        let binData = [];
        binData.push(r);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binData, { type: dataType }));
        if (fileName) {
          downloadLink.setAttribute('download', fileName);
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
        if (!!downloadLink.parentNode)
          downloadLink.parentNode.removeChild(downloadLink);
      }), catchError(e => this.handleHttpError(e, this._router)));
    // .subscribe((r: any) => {
    //   console.debug('downloadFile Response', r);
    //   const dataType = r.type;
    //   let binData = [];
    //   binData.push(r);
    //   let  downloadLink = document.createElement('a');
    //   downloadLink.href = window.URL.createObjectURL(new Blob(binData, {type: dataType}));
    //   if (fileName) {
    //     downloadLink.setAttribute('download', fileName);
    //   }
    //   document.body.appendChild(downloadLink);
    //   downloadLink.click();
    //   downloadLink.parentNode.removeChild(downloadLink);
    // }, e => {
    //     if (typeof(e) === 'object' && e.then) {
    //       e.then(x => this.errorMessage.toPromise(x));
    //     } else {
    //       handleError(e);
    //     }
    //     }
    // })
    // console.debug('httpGet', url, options, params);
    // return this._http.get(this.apiUrl + url, options).pipe(catchError(e => this.handleHttpError(e, this._router)));
  }

  public downloadLink(url: string): void {
    let options = this.getHttpOptions();
    // this._http.get(this.apiUrl + url, options).pipe(catchError(e => this.handleHttpError(e, this._router)))
    this._http.get(this.apiUrl + url, options)
    .pipe(catchError(e => {
      if (e.status == HTTP_STATUS_BADREQUEST && e.error.indexOf('no files') >= 0) {
        this.alert.warn('There are no files present for this record.');
        return of();
      } else {
        return this.handleError(e);
      }
    }))
    .subscribe((r:any) => {
      let downloadLink = document.createElement('a');
      downloadLink.href = r.url;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      if (!!downloadLink.parentNode)
        downloadLink.parentNode.removeChild(downloadLink);
    });
  }

  // public downloadLink(url: string, fileName: string): void {
  //   let options = this.getHttpOptions();
  //   const that = this;
  //   this._http.get(this.apiUrl + url)
  //     .subscribe((link: string) => {
  //       options['responseType'] = 'blob' as 'json';
  //       this._http.get(url, options)
  //         .subscribe((r: any) => {
  //           const dataType = r.type;
  //           let binData = [];
  //           binData.push(r);
  //           let downloadLink = document.createElement('a');
  //           downloadLink.href = window.URL.createObjectURL(new Blob(binData, { type: dataType }));
  //           if (fileName) {
  //             downloadLink.setAttribute('download', fileName);
  //           }
  //           document.body.appendChild(downloadLink);
  //           downloadLink.click();
  //           downloadLink.parentNode.removeChild(downloadLink);
  //         }, catchError(e => this.handleHttpError(e, this._router)));
  //     }, catchError(e => this.handleHttpError(e, this._router)));
  // }

  get isLoggedIn(): boolean {
    return !!this.getAccessToken() && this.getExpires() > new Date().getTime();
  }

  public checkLogin(): boolean {
    if (!this.isLoggedIn) {
      this._router.navigate([LoginUrl]);
      return false;
    }
    return true;
  }

  private concatError(e: any) {
    let desc: string = '';
    for (var key in e) {
      desc += e[key] + '\n';
    }
    if (desc.length > 200) {
      desc = desc.substr(0, 200) + '...';
    }
    return desc;
  }


  public httpErrorMessage(response: HttpErrorResponse): string {
    // console.debug('httpErrorMessage', response);
    if (response.headers) {
      var applicationError = response.headers.get('Application-Error');
      if (applicationError) {
        return applicationError;
      }
    }
    switch (response.status) {
      case HTTP_STATUS_SERVER_ERROR: // Server error
        {
          let msg = 'Server Error';
          if (response.error) {
            if (response.error.traceId) {
              msg += ': ' + response.error.traceId;
            }
            if (response.error.title) {
              msg += ': ' + response.error.title
            }
          }
          return msg;
        }
      case HTTP_STATUS_UNAUTHORIZED:
        return 'Not Logged In.';
      case HTTP_STATUS_NOT_FOUND:
        return 'Page (' + response.url + ') not found.'
      case HTTP_STATUS_FORBIDDEN:
        return 'You do not have access to this feature.';
      case HTTP_STATUS_NOT_ACCEPTABLE:
        return "No data present.";
    }
    if (response.error && response.error.errors) {
      // const e = response.error;
      // if (e.type && e.type === 'text/plain' && e.text) {
      //   return e.text();
      // }
      // if (!!response.error.errors) {
      const errors = response.error.errors;
      if (typeof errors === 'string') {
        return errors;
      }
      let desc = '';
      Object.keys(errors).forEach(key => desc += errors[key] + ' ');
      return desc;
      // }
    }
    if (response.error && typeof response.error === 'string') {
      return response.error;
    }
    if (response.statusText) {
      return response.statusText === 'Unknown Error' ? 'Server Error' : response.statusText;
    }
    return response.message;
  }

  public handleHttpError(response: any, router: Router) {
    // console.debug('handleHttpError', response);
    if (response && response.status == HTTP_STATUS_UNAUTHORIZED) {
      router.navigate([LoginUrl]);
      return throwError('Not Logged In');
    }
    if (response.error && response.error.type && response.error.type === 'text/plain' && response.error.text) {
      response.error.text().then((t: string) => this.alert.error(t));
      return throwError(() => new Error(response.error));
    } else {
      const msg = this.httpErrorMessage(response);
      this.alert.error(msg);
      return throwError(() => new Error(msg));
    }
  }

  genGoogleTotp(): Observable<IGenTotpResult> {
    const options = this.getHttpOptions();
    return this._http.get<IGenTotpResult>(this.apiUrl + 'account/genGoogleTotp', options)
      .pipe(catchError(e => this.handleHttpError(e, this._router)));
  }
}

export interface FileUploadModel {
  data: File;
  state: string;
  inProgress: boolean;
  progress: number;
  canRetry: boolean;
  canCancel: boolean;
  sub?: Subscription;
  error?: string;
  id?: string;
}

export interface IGenTotpResult {
  qrImage: string;
  manualSetupCode: string;
}
